@import "colors";
@import "values";

.dashboard-winners{
    .entries{
        background: white;
        padding: 40px !important;
        margin-bottom: 20px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // categspecific
        .entry-wrapper{
            padding: 0 !important;
            position: relative;
            .entry-details{
                text-align: center;
                .mid-section{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    margin-bottom: 20px;
                    .entry-ranking,
                    .entry-votes{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 50px 1fr;
                        align-items: center;
                        h2{
                            font-size: 50px !important;
                            font-weight: bold;
                        }
                    }
                    .entry-votes{
                        border-left: 2px solid white;
                        h4{
                            font-size: 20px !important;
                            font-weight: bold;
                        }
                    }
                    label{
                        font-size: 12px;
                    }
                }
                .entry-category{
                    .catname-main{
                        font-size: 18px;
                        text-transform: uppercase;
                    }
                    .catname-sub{
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                }
                .entry-socials{
                    margin-top: 20px;
                    .svg-inline--fa{
                        height: 2em !important;
                    }
                }
            }
            .award-icon{
                height: 100px;
                position: absolute;
                top: -20px;
                left: -20px;
                z-index: 111;
            }
            &.regionalfaves-luzon,
            &.regionalfaves-visayas,
            &.regionalfaves-mindanao{
                .entry-name{
                    display: none;
                }
                .mid-section{
                    margin-top: 20px;
                }

                color: white;
                background: $category-orange;
                .entry-ranking,
                .entry-votes{
                    color: $theme-green;
                }
                .mid-section .entry-votes{
                    border-color: $theme-green;
                }
            }
            &.hot100{
                color: white;
                background: $theme-salmon;
                .entry-ranking,
                .entry-votes{
                    color: $category-yellow;
                }
                .mid-section .entry-votes{
                    border-color: $category-yellow;
                }
            }
            &.premiumburger,
            &.ricemeal,
            &.friesonionrings{
                color: white;
                background: $category-green;
                .entry-ranking,
                .entry-votes{
                    color: $category-yellow;
                }
                .mid-section .entry-votes{
                    border-color: $category-yellow;
                }
            }
            &.regularburger,
            &.pasta,
            &.combomeals{
                color: $category-green;
                background: $category-yellow;
                .entry-ranking,
                .entry-votes{
                    color: $theme-orange-dark;
                }
                .mid-section .entry-votes{
                    border-color: $theme-orange-dark;
                }
            }
            &.pizza,
            &.coffee,
            &.healthyoptions{
                color: white;
                background: $category-orange;
                .entry-ranking,
                .entry-votes{
                    color: $theme-green;
                }
                .mid-section .entry-votes{
                    border-color: $theme-green;
                }
            }
            &.bonelesschicken,
            &.desserts{
                color: $theme-violet;
                background: $category-blue;
                .entry-ranking,
                .entry-votes{
                    color: $bootstrap-red;
                }
                .mid-section .entry-votes{
                    border-color: $bootstrap-red;
                }
            }
            &.colddesserts,
            &.roastedchicken{
                color: $theme-violet;
                background: $category-purple;
                .entry-ranking,
                .entry-votes{
                    color: $theme-salmon;
                }
                .mid-section .entry-votes{
                    border-color: $theme-salmon;
                }
            }
            &.friedchicken,
            &.milktea{
                color: white;
                background: $category-red;
                .entry-ranking,
                .entry-votes{
                    color: $theme-yellowGreen;
                }
                .mid-section .entry-votes{
                    border-color: $theme-yellowGreen;
                }
            }   
        }
    }

    .leaderboard-tabs{
        // padding: 0 30px;
        // max-width: 1200px;
        margin: auto;
        .gffTabs{
            &-tab{
                background: $theme-green;
                border-color: $theme-green-light;
                border-width: 2px;
                color: lighten($theme-green-light, 10%);
                transition: all 200ms;
                font-size: clamp(1rem, 2vw, 1.2rem);
                padding: 10px clamp(20px, 2vw, 50px);
                &.active{
                    background: $theme-green-light;
                    color: $theme-green;
                }
            }
            &-panels{
                border-color: $theme-green-light;
                border-width: 2px;
                background: transparentize($theme-green-light, .75);
                .national{
                    display: grid;
                    text-align: center;
                    // background: $theme-yellowSoft !important;
                }
                .regional{
                    background: $theme-green-light;
                    color: $theme-green-dark;
                }
                .trending{
                    background: white;
                    color: $theme-cyan;
                }
            }
        }
    }
}

//PRODUCT DISPLAY ADJUSTMENTS
@media screen and (max-width: 1100px) {.dashboard-winners .entries{grid-template-columns: repeat(3, 1fr) !important;}}
@media screen and (max-width: 850px) {.dashboard-winners .entries{grid-template-columns: repeat(2, 1fr) !important;}}
@media screen and (max-width: 800px) {.dashboard-winners .entries{grid-template-columns: repeat(3, 1fr) !important;}}
@media screen and (max-width: 700px) {.dashboard-winners .entries{grid-template-columns: repeat(2, 1fr) !important;}}
@media screen and (max-width: 450px) {.dashboard-winners .entries{grid-template-columns: repeat(1, 1fr) !important;}}

@media screen and (max-width: 1150px) {
    .dashboard-winners .entries{
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-winners .entries{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}