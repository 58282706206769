@import "values";
@import "colors";

@media screen and (max-width: 991px) {
    .edit-profile-modal .modal-dialog{max-width: 90% !important;}

}
@media screen and (max-width: 991px) {
    .edit-profile-modal .modal-dialog{max-width: 90% !important;}

}

@media screen and (max-width: 800px) {
    section.login{
        height: calc(100dvh - $height-footer);
    }
    .sidenav{
        left: calc(($width-sidenav-max * -1) - 10px ) !important;
        transition: left 500ms;
        z-index: 101;
        width: $width-sidenav-max !important;
        max-width: $width-sidenav-max !important;
        min-width: $width-sidenav-max !important;
        height: 100dvh;
        z-index: 105;
        &.opened{
            left: 0 !important;
        }
    }
    .nav-backdrop{
        position: absolute;
        z-index: 100;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(56, 56, 56, 0.396);
        transition: visibility 500ms;
        display: none;
        height: 100vh;
        z-index: 104;
        &.opened{
            display: block;
        }
    }
    .authenticated{
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        .header-title{
            padding-left: 2.5rem !important;
            margin-bottom: 1rem;
        }
        .nav-button{
            display: flex !important;
        }
    }
    .modal.edit-profile-modal {
        .modal-dialog{
            max-width: 500px !important;
        }
        .section-wrapper{
        grid-template-columns: 1fr !important;
            &:last-of-type{margin: 1rem 0 !important;}
            .section-label{
                margin-bottom: 10px !important;
                padding-left: 5px;
                text-align: center;
            }
            .section-input.image{
                flex-direction: column;
                align-items: center;
                .imagefile-wrapper{
                    height: calc($width-img_input / 1.75);
                    width: calc($width-img_input / 1.75);
                }
                .imagefile-reqs{
                    width: calc($width-img_input / 1.75);
                    margin-top: 5px !important;
                }
            }
            form{
                text-align: center !important;
            }
        }
    }
    footer{
        z-index: 102 !important;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
    }
}

//PRODUCT DISPLAY ADJUSTMENTS
@media screen and (max-width: 1100px) {section.entries .category-body{grid-template-columns: repeat(3, 1fr) !important;}}
@media screen and (max-width: 850px) {section.entries .category-body{grid-template-columns: repeat(2, 1fr) !important;}}
@media screen and (max-width: 800px) {section.entries .category-body{grid-template-columns: repeat(3, 1fr) !important;}}
@media screen and (max-width: 700px) {section.entries .category-body{grid-template-columns: repeat(2, 1fr) !important;}}
@media screen and (max-width: 450px) {section.entries .category-body{grid-template-columns: repeat(1, 1fr) !important;}}



// LOGIN DISPLAY ADJUSTMENTS
@media screen and (max-width: 1430px){
    .login{
        .login-logo{
            max-width: 370px !important; 
            margin-left: 15% !important
        }
        .phone-food{height: 85% !important; }
        .form-wrapper{
            justify-content: flex-end !important; 
            padding-right: 15% !important;
            .form-inner-wrapper{
                width: 70% !important;
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .login {
        .phone-food{right: -125px !important;}
    }
}

@media screen and (max-width: 1200px){
    .login{
        .login-logo{
            max-width: 300px !important; 
        }
        .form-wrapper{
            padding-right: 5% !important;
        }
    }
}

@media screen and (max-width: 1100px){
    .login-banner{display: none !important;}
    .login{
        grid-template-columns: 1fr !important;
    }
    section.login .form-wrapper{
        background: url("../images/mobile-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        padding: 2rem;
        justify-content: center !important;
        flex-direction: column;
        .mobile-logo{
            display: block !important;
        }
        .form-inner-wrapper{
            background:  transparentize($theme-green, .15);
            box-shadow: 0 0 20px 0 black;
            max-height: unset !important;
            max-width: 500px !important;
            width: 100% !important;
            padding: 3rem;
            .input-group {
                &:focus-within label,
                label.focused{
                    background: $theme-green-dark !important;
                }
            }
            .forgot-password-btn:hover{
                color: $theme-green-dark !important;
            }
        }
    }
}
@media screen and (max-width: 500px){
    .loader-wrapper .inner-wrapper{
        transform: scale(.65) !important;
    }
    section.login .form-wrapper{
        padding:  0 !important;
        .form-inner-wrapper{
            height: 100% !important;
            width: 100% !important;
            max-width: unset !important;
            .mobile-logo{
                margin-bottom: 3rem !important;
                height: 250px !important;
            }
            .input-group input,
            .btn{
                font-size: 1.25rem !important;
            }

            .forgot-password-btn{font-size: 1rem !important;}
        }
    }
}