@import url("https://cdn.jsdelivr.net/npm/font-awesome@4.7.0/css/font-awesome.css");
@import "colors";
@import "values";

@font-face {
    font-family: "GrabV2";
    src: url("../fonts/grab/GrabCommunityENv2Inline.ttf") format("truetype"),
        url("../fonts/grab/GrabCommunityENv2_woff.woff") format("woff"),
        url("../fonts/grab/GrabCommunityENv2_woff2.woff2") format("woff2");
}

.clearfix{
    transform: scale(1);
    display: table;
}
.clearfix:before, .clearfix:after {
    content:"";
    display: table;
}
.clearfix::after{
    clear: both;
}

.photo-container{
    width: 100%;
    .parent{
        width: 100%;
        position: relative;
        .bgPhoto{
            width: 100%;
            position: inherit;
        }
        .fgPhoto{
            position: absolute;
            width: 54%;
            transform: translate(45%, 48%);
        }
    }
}

.modal{
    .modal-dialog{
        .modal-content{
            p{
                color:$theme-green-dark-mid;
                text-align: center;
                margin-bottom: 20px;
                font-size: 20px;
            }
            .head-btn-container{
                position: static;
                justify-content: right;
                button{
                    margin: 10px;
                    position: static;
                }
            }
        }
    }
}

body,
html{
    height: 100dvh !important;
    width: 100% !important;
    // overflow: auto;
    font-family: 'Grab Medium', Arial, sans-serif;
    font-size: 16px;
    font-weight: 450;
    
}
:root {
    color-scheme: light only;
}
#root{
    width: 100%;
    height: 100%;
}

main{
    overflow-x: hidden;
    height: calc(100% - $height-footer);
    position: relative;
    &.authenticated{
        width: calc(100% - $width-sidenav);
        min-width: calc(100% - $width-sidenav-max);
        max-width: calc(100% - $width-sidenav-min);
        margin: 0 0 0 auto;;
        background-color: $theme-green;
        color: white;
    }
}

.loader {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    display: block;
    transform: translate(-50%, -50%) scale(2);
    position: absolute !important;
    top: 5px;
    right: -44px;
    &-wrapper{
        position: absolute;
        z-index: 999999;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        &.post-loader{
            background: rgba(255, 255, 255, 0.9) !important;
        }
        .inner-wrapper{
            position: relative;
            img{
                height: 100px;
                position: relative;
                z-index: 1;
            }
        }
        svg{
            height: 126.19px;
            width: 406.71px;
            position: relative;
            z-index: 1;
            .svg-white{
                fill: white;
            }
        }
    }
}

.nav-button{
    height: 20px;
    width: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
    top: 1.75rem;
    left: 1.5rem;
    z-index: 100;
    display: none;
    div{
        height: 22%;
        width: 100%;
        background: white;
    }
}
.sidenav{
    position: fixed;
    width: $width-sidenav;
    max-width: $width-sidenav-max;
    min-width: $width-sidenav-min;
    background-color: $theme-green;
    height: calc(100% - $height-footer);
    z-index: 1;
    color: white;
    border-right: 2px solid $theme-green-light;
    overflow-y: auto;
    overflow-x: hidden;
    .wrapper{
        min-height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .logo{
            padding: 20px 10px;
            a{
                display: block;
                height: 100%;
                max-width: 120px;
                margin: auto;
            }
            img{
                width: 100%;
            }
        }
        .merchant,
        .logo{
            position: relative;
            padding-bottom: 1rem !important;
            &:after{
                content: "";
                height: 2px;
                width: 90%;
                position: absolute;
                bottom: -1px;
                left: 0;
                background: $theme-green-light;
            }
        }
        .merchant{
            padding: 20px 10px;
            &-inner{
                text-align: center;
                img{
                    height: 100px;
                    width: 100px;
                    border-radius: 100%;
                    border: 3px solid $theme-green-dark;
                    object-fit: 110% 110%;
                    object-position: center;
                    margin-bottom: 10px;
                }
                .profile-edit{
                    display: inline-block !important;
                    width: unset !important;
                    margin-left: 10px;
                    cursor: pointer;
                    font-size: 1.1rem;
                    color: $theme-green-light;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    &:hover{
                        color: $theme-green-dark;
                        filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.2));
                    }
                }
                .merchant-name{
                    overflow-wrap: break-word;
                    position: relative;
                    padding: 0 22px;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                }
            }
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
        }
        .logout{
            width: 100%;
            padding: 20px 40px;
        }
        li{
            width: 100%;
            padding: 30px 40px 0;
        }
        a{
            display: inline-block;
            width: 100%;
            font-size: 16px;
            text-transform: uppercase;
            cursor: pointer;
            &.active{
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
        
    }
}

section{
    padding: 1.5rem;
    &.login{
        display: grid;
        padding: 0 !important;
        grid-template-columns: 55% 1fr;
        height: calc(100vh - $height-footer);
        background-color: $theme-orange;
        .title{
            color: $theme-green-dark-mid;
            margin-bottom: 1rem;
        }
        .login-banner{
            // overflow-y: hidden;
            background: $theme-green;
            position: relative;
            z-index: 0;
            .login-inner-wrapper{
                display: grid;
                grid-template-columns: 1fr calc($width-kv - 50px);
                align-items: center;
                text-align: right;
                position: relative;
                height: 100%;
            }
            .login-logo{
                width: 90%;
                max-width: 350px;
                // margin-left: 15%;
                margin: auto;
            }
            .phone-food{
                position: absolute;
                bottom: 0;
                object-fit: contain;
                // width: $width-kv;
                height: 95%;
                right: -100px;
                object-position: bottom;
                z-index: 1;
            }
        }
        .form-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: auto;
            position: relative;
            z-index: 1;
            .mobile-logo{
                height: 200px;
                filter: drop-shadow(0 0 10px transparentize($theme-green-dark-mid, .25));
                object-fit: contain;
                object-position: center;
                margin-bottom: 3rem;
                display: none;
            }
            .form-inner-wrapper{
                background: $theme-green-dark;
                padding: 3rem 1rem;
                width: 60%;
                max-width: 600px;
                border-radius: 5px;
                box-shadow: 0 0 50px 0 transparentize(black, .95);
                text-align: center;
                max-height: 500px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .input-group{
                    background: white;
                    border-width: 2px;
                    &:focus-within label,
                    label.focused{
                        background: $theme-green !important;
                        color: white !important;
                    }
                    input{
                        font-size: 14px;
                    }
                }
                .btn{
                    box-shadow: 6px 6px 0 0 black !important;
                    font-size: 14px;
                    &:hover,
                    &:active,
                    &:focus-visible{
                        background: white !important;
                    }
                }
            }
        }
    }
    &.userpage{
        .header-title{
            font-family: "Grab","INTER";
            padding: 0 1rem;
            // text-transform: uppercase;
            .page-info{
                font-size: 16px;
                text-transform: initial;
            }
        }
    }
    &.entries{
        padding: 10px 0 0 !important;
        .category{
            &-wrapper{
                background: white;
                margin-bottom: 1rem;
                padding: 15px;
                border-radius: 5px;
            }
            &-header{
                background: $theme-orange;
                padding: 10px 1rem 10px 3.5rem;
                border-radius: 100px;
                color: $theme-green-dark;
                position: relative;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px;
                &-icon{
                    height: 25px;
                    width: 30px;
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                    object-fit: contain;
                    object-position: center;
                }
            }
            &-body{
                padding: 20px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
        }
        .entry{
            &-wrapper{
                background: $theme-entryItem;
                margin: 0 5px;
                padding: 5px;
                border-radius: 3px;
                max-width: 300px;
                margin-bottom: 10px;
            }
            &-photo{
                height: 200px;
                position: relative;
                .header-icon{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .entry-edit-photo{
                    cursor: pointer;
                    position: absolute;
                    z-index: 1;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    font-size: 30px;
                    &:hover svg{
                        // color: $theme-green;
                        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 1));
                    }
                    svg{
                        position: absolute;
                        bottom: 5px;
                        right: 5px;
                        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 1));
                    }
                }
            }
            &-name{
                font-size: 16px;
                font-weight: bold;
                padding: 5px;
                min-height: 55px;
            }
        }
    }
}

.gffTabs{
    .gffTabs{
        &-tabs{
            display: flex;
            width: 100%;
        }
        &-tab{
            // width: 100%;
            text-align: center;
            padding: 10px 40px;
            background: grey;
            cursor: pointer;
            border-radius: 10px 10px 0 0;
            border: 1px solid white;
            border-bottom: none;
            font-family: "Grab";
            &.active{
                background: white;
                color: grey;
            }
        }
        &-panels{
            background: transparent;
            border: 1px solid white;
        }
        &-panel{
            padding: 20px;
            // background: red;
        }
    }
}

.modal{
    .modal-content{
        position: relative;
        border-radius: 5px;
    }
    .modal-header{
        border: none;
        padding: 0;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 99;
        .modal-title{
            font-size: 1rem;
            color: $theme-green-dark-mid;
            width: 100%;
            text-align: center;
        }
        .btn-close{
            outline: none !important;
            box-shadow: none !important;
            padding: 0;
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 20px;
            &:hover{
                filter: invert(50%) sepia(13%) saturate(320799%) hue-rotate(-22deg) brightness(95%) contrast(80%);
            }
        }
    }
    &.login-modal{
        color: $theme-green-dark-mid;
        .countdown-wrapper{
            font-size: .9rem;
            // padding: 0 5px;
            width: 100%;
            // display: flex;
            margin-bottom: 20px;
            // justify-content: space-between;
            .otp-countdown{
                text-align: center;
                span{
                    font-weight: bold;
                    font-size: 16px;
                }
            }
            .resend-code{
                color: $theme-orange-dark;
                display: block;
                cursor: pointer;
                text-decoration: underline;
                &:hover{
                    font-weight: bold;
                }
            }
            .resend-countdown{
                color: gray;
                display: block;
            }
        }
        .input-group#input-otp{
            margin-bottom: 10px;
        }
        .modal-title{
            font-size: 1rem !important;
        }
        .modal-body{
            text-align: center;
            padding: 3rem 2rem;
            p{
                font-size: .9rem;
                margin-bottom: 20px;
            }
            .btn{
                margin-top: 10px !important;
            }
        }
        .otp-wrapper{
            color: black;
            .security-icon{
                height: 80px;
                margin-bottom: 1rem;
            }
            h5{
                color: $theme-green-dark-mid;
                font-weight: bold;
                margin-bottom: 1rem;
            }
            .input-group{
                border: 2px solid $theme-green-dark-mid;
                border-radius: 7px;
                input{
                    font-size: 16px;
                }
            }
            .resend-otp{
                color: gray;
                a{
                    color: black;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    cursor: pointer;
                    &:hover{
                        color: $theme-salmon;
                    }
                }
            }
        }
    }
    &.edit-profile-modal{
        color: $theme-green-dark;
        text-align: right;
        .error .error-message{
            text-align: center;
        }
        .input-group{
            border-width: 2px !important;
        }
        .modal-body{
            padding: 3rem 2rem 4rem;
        }
        .section-wrapper{
            display: grid;
            grid-template-columns: 170px 1fr;
            text-align: left;
            column-gap: 10px;
        }
        .section-input{
            &.image{
                display: flex;
                align-items: center;
                padding-left: 2px;
                .imagefile-wrapper{
                    height: 150px;
                    width: 150px;
                }
            }
        }
    }
}

.form-input-focused-props{
    color: $theme-green !important;
    top: 0px;
    font-size: 10px;
    transition: all 200ms;
    opacity: 1;
    left: calc($padding-input * .5);
    background: white !important;
}
form{
    .input-group{
        padding: 10px 0px 1px 1px;
        background: transparent;
        font-size: 1rem;
        margin-bottom: 1rem;
        position: relative;
        border: 1px solid $theme-green;
        &.no-label{
            padding-top: 1px !important;
            label{
                display: none;
            }
            input{
                padding-top: 10px !important;
            }
        }
        &.disabled{
            height: 57px;
            border-color: rgb(117, 117, 117);
            background: rgb(236, 236, 236);
            label{
                background: none !important;
            }
            label.focused,
            &:focus-within label{
                color: grey !important;
                top: 50% !important;
                font-size: 14px !important;
            }
        }
        &:focus-within label{
            @extend .form-input-focused-props;
        }
        &.input-center{
            &:focus-within label,
            label.focused{
                width: unset;
            }
            label{
                transform: translate(-50%, -50%);
                left: 50%;
            }
            input{
                text-align: center;
            }
        }
        &.input-password{
            input{
                padding: calc($padding-input * 1.2) calc($padding-input * 5) $padding-input calc($padding-input * 2) !important;
                padding-top: calc($padding-input * 1.2 - 10px) !important;
            }
            &.forgot-password{
                margin-bottom: calc(2rem + 20px);
                .forgot-password-btn{
                    cursor: pointer;
                    position: absolute;
                    transform: translateX(-50%);
                    bottom: -40px;
                    left: 50%;
                    font-size: 12px;
                    color: $theme-green-light;
                    transition: all 250ms;
                    text-decoration: underline;
                    text-underline-offset: 3px;   
                    width: 100%;
                    &:hover{
                        color: $theme-green;
                    }
                }
            }
            &.new-password{
                &.error{
                    border-color: red !important;
                    &:focus-within label,
                    label.focused{
                        color: red !important
                    }
                    &.confirm{
                        margin-bottom: 2rem;
                        &:after{
                            content: "Passwords don't match";
                            position: absolute;
                            bottom: -1.25rem;
                            font-size: 12px;
                            color: red;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            .password-group{
                width: 100%;
            }
            .eye-toggle{
                position: absolute;
                right: calc($padding-input * 2);
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                font-size: 1.25rem;
                cursor: pointer;
                width: calc($padding-input * 2.5);
                text-align: center;
                &:hover{
                    color: $theme-green;
                    svg{
                        opacity: 1 !important;
                    }
                }
            }
        }
        label, input{
            font-size: 14px;
            padding: 0;
            border: none;
            outline: none;
            display: block;
        }
        label{
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            left: calc($padding-input * 1.25);
            display:inline-block;
            transition: all 200ms;
            background: none !important;
            padding: 0 5px;
            color: grey;
            text-transform: uppercase;
            opacity: .8;
            &.focused{
                @extend .form-input-focused-props;
            }
        }
        input{
            display: block;
            height: 100%;
            width: 100%;
            background: transparent;
            position: relative;
            z-index: 1;
            font-size: 20px;
            padding: calc($padding-input * 1.2) calc($padding-input * 2) $padding-input;
            padding-top: calc($padding-input * 1.2 - 10px);
            color: $theme-green-dark-mid;
        }
    }
    .error-message{
        color: rgb(250, 66, 66);
        font-size: 12px;
        margin-bottom: .75rem;
        display: none;
    }
    .imagefile-wrapper{
        width: $width-img_input;
        height: $width-img_input;
        position: relative;
        cursor: pointer;
        outline: 2px solid $theme-entryItem;
        position: relative;
        &:hover::after{
            opacity: 1 !important;
        }
        &.withImage:before{
            position: absolute;
            bottom: 10px;
            right: 10px;
            height: 50px;
            width: 50px;
            content: "";
            z-index: 2;
            background: url("../images/uploadIcon.png");
            background-size: contain;
            filter: drop-shadow(0 0 5px transparentize(black, .75));

        }
        &::after{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: "Choose a photo";
            background: transparentize(black, .5);
            z-index: 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            opacity: 0;
        }
        input{
            // opacity: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            position: relative;
            opacity: 0;
            cursor: pointer;
        }
        img{
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
            object-position: center;
        }
    }
    .imagefile-reqs{
        width: $width-img_input;
        text-align: left;
        font-size: 10px;
        color: black;
        p{
            font-size: 10px !important;
            margin-bottom: 0 !important;
            text-align: left !important;
        }
        .wrong{
            color: red !important;
            font-weight: bold;
            animation: flickerAnimation .5s 3;
        }
    }
    &.error{
        .error-message{
            display: block;
        }
        .input-group{
            border-color: red;
            label.focused,
            &:focus-within label{
                color: red !important;
            }
        }
    }
}

.note{
    font-size: .75rem !important;
    opacity: .75;
}

footer{
    background-color: $theme-green-light;
    height: $height-footer;
    text-align: center;
    color: $theme-green-dark-mid;
    display: grid;
    align-items: center;
    font-size: 14px;
    z-index: 2;
    position: relative;
}

.text{    
    &-orange{
        color: $theme-orange;
    }
}

.btn{
    padding: 10px 30px;
    display: inline-block;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 6px 6px 0 0 transparentize($theme-green-dark-mid, .5);
    border: none;
    transition: all 300ms;
    &-sm{
        font-size: 15px;
        padding: 8px 20px;
        box-shadow: 3px 3px 0 0 transparentize($theme-green-dark-mid, .75);
    }
    &:disabled{
        background: rgb(210, 210, 210) !important;
        color: grey !important;
    }
    &:hover{
        transition: all 300ms;
        // filter: contrast(1.5);
    }
    &-green{
        background-color: $theme-yellowGreen;
        color: $theme-green-dark-mid;
        &:hover,
        &:focus-visible,
        &:active{
            background: $theme-green-dark !important;
            color: $theme-green !important;
        }
    }
    &-main-green{
        background-color: $theme-green;
        color: white;
        &:hover,
        &:focus-visible,
        &:active{
            background: $theme-green-dark !important;
            color: $theme-green !important;
        }
    }
    &-orange{
        background-color: $theme-orange;
        color: $theme-green-dark;
        &:hover,
        &:focus-visible,
        &:active{
            background: $theme-orange-dark !important;
            color: white !important;
        }
    }
    &-red{
        background-color: $bootstrap-red;
        color: $theme-green-light;
        &:hover,
        &:focus-visible,
        &:active{
            background: red !important;
            color: white !important;
        }
    }
    &-gray{
        background-color: gray;
        color: white;
        &:hover,
        &:focus-visible,
        &:active{
            background: rgb(64, 64, 64) !important;
            color: $theme-green-light !important;
        }
    }
}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
