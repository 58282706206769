$height-footer: 50px;

$width-sidenav: 20%;
$width-sidenav-max: 300px;
$width-sidenav-min: 170px;
$width-kv: 200px;

$width-img_input: 300px;

$padding-sectionBanner: 2em;
$padding-input: 10px;
