@import "colors";
@import "values";

.leaderboard{
    min-height: 100% !important;
    background: $theme-cyan;
    transition: background 200ms;
    .header-title{
        margin-bottom: $padding-sectionBanner;
    }
    &.active-regional{
        background: $theme-orange;
    }
    &.active-trending{
        background: $theme-salmon;
        .header-title{
            color: $category-yellow;
        }
    }
    .leaderboard-tabs{
        // padding: 0 30px;
        max-width: 1200px;
        margin: auto;
        .gffTabs{
            &-tab{
                background: $theme-green;
                border-color: $theme-green;
                border-width: 2px;
                color: lighten($theme-green, 10%);
                transition: all 200ms;
                font-size: clamp(1rem, 2vw, 1.2rem);
                padding: 10px clamp(20px, 2vw, 50px);
                &.active{
                    background: transparent;
                    color: $theme-green;
                    &.regional{
                        background: $theme-green-light;
                    }
                    &.trending{
                        color: $category-yellow;
                    }
                }
            }
            &-panels{
                border-color: $theme-green;
                border-width: 2px;
                .national{
                    display: grid;
                    text-align: center;
                }
                .regional{
                    background: $theme-green-light;
                    color: $theme-green-dark;
                }
                .trending{
                    background: white;
                    color: $theme-cyan;
                }
            }
        }
    }
    .regional.desktop{
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 5px;
        section{
            background: white;
            border-radius: 10px;
            padding: 0;
            .section-header{
                border-radius: 10px;
                color: black;
                padding: 5px 10px;
                font-size: 45px;
                font-family: "GrabV2";
                outline: none !important;
                transition: filter 300ms;
                border: 2px solid white;
                border-bottom: none !important;
            }
            &.luzon{
                .section-header{
                    color: $regionL-txt;
                    background: $regionL-bg;
                    border-color: $regionL-bg;
                }
            }
            &.visayas{
                .section-header{
                    color: $regionV-txt;
                    background: $regionV-bg;
                    border-color: $regionV-bg;
                }
            }
            &.mindanao{
                .section-header{
                    color: $regionM-txt;
                    background: $regionM-bg;
                    border-color: $regionM-bg;
                }
            }
            .section-body{
                text-align: left;
                background: white;
                color: $theme-green-dark;
                padding: calc($padding-sectionBanner * 1.1) 1rem 0;
                border-radius: 10px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(3, 100px);
                .regions-item-wrapper{
                    display: grid;
                    grid-template-columns: 50px 1fr;
                    margin-bottom: 1rem;
                    font-size: .9rem;
                    text-align: center;
                    text-transform: uppercase;
                    color: $theme-green-dark-mid;
                    .rank{
                        font-size: 1.5rem;
                        line-height: 1;
                    }
                }

            }
        }
    }
}


.categories-wrapper{
    display: inline-grid;
    box-sizing: border-box;
    max-width: 1200px;
    column-gap: 3px;
    row-gap: 3px;
    margin: auto;
    grid-template-areas: 
        "premium regular pizza fried roasted"
        "resultsWrapper resultsWrapper resultsWrapper resultsWrapper resultsWrapper"
        "boneless healthy pasta rice combo"
        "cold dessert coffee milktea fries" 
        !important;

    .PremiumBurger-wrapper{grid-area: premium;}
    .RegularBurger-wrapper{grid-area: regular;}
    .Pizza-wrapper{grid-area: pizza;}
    .FriedChicken-wrapper{grid-area: fried;}
    .BonelessChicken-wrapper{grid-area: boneless;}
    .RoastedChicken-wrapper{grid-area: roasted;}
    .HealthyOptions-wrapper{grid-area: healthy;}
    .Pasta-wrapper{grid-area: pasta;}
    .RiceMeal-wrapper{grid-area: rice;}
    .ComboMeals-wrapper{grid-area: combo;}
    .ColdDesserts-wrapper{grid-area: cold;}
    .Desserts-wrapper{grid-area: dessert;}
    .Coffee-wrapper{grid-area: coffee;}
    .MilkTea-wrapper{grid-area: milktea;}
    .FriesOnionRIngs-wrapper{grid-area: fries;}
    .results-wrapper{grid-area: resultsWrapper;}

    .categorybuttonWrapper{
        margin: 0;
        cursor: pointer;
        &:hover img{
            border: 2px solid lighten(orangered, 10%) ;
        }
        .categorybutton{
            max-width: 200px;
            width: 100%;
            margin: 0;
            height: auto;
            box-sizing: border-box;
            border-radius: 10px;
            border: 2px solid transparent;
        }
    }
    
    .results-wrapper{
        display: block !important;
        .results-loader{
            background: $theme-green;
            margin: 1px 5px 20px;
            min-height: 150px;
            border-radius: 8px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .results-inner-wrapper{
            margin: 1px 5px 20px;
            overflow-y: auto;
            padding: 3px 3px  0;
            max-height: 325px;
            &::-webkit-scrollbar{
                width: 10px;
                &-track{
                    background: transparent;
                }
                &-thumb{
                    background: darken($category-green, 10%);
                    border-radius: 100px;
                    cursor: pointer;
                }
            }
            .results-item{
                background: green;
                margin-bottom: 5px;
                border-radius: 8px;
                padding: 10px 10px 10px 15%;
                font-size: 16px;
                font-weight: bold;
                color: white;
                display: grid;
                grid-template-columns: 40px 1fr 35%;
                text-align: left;
                align-items: center;
                &:hover{
                    filter: brightness(110%) contrast(110%) saturate(110%);
                }
                span{
                    display: inline-block;
                    padding-right: 10px;
                }
                &.header{
                    filter: none !important;
                    // background: none !important;
                    color: $theme-green-dark-mid !important;
                    padding-bottom: 5px !important;
                    padding-top: 5px !important;
                    span{
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
                .rank{
                    background: $category-yellow;
                    color: $category-green;
                    font-size: 20px;
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    display: grid;
                    text-align: center;
                    vertical-align: middle;
                }
            }
        }
    }

    &.PremiumBurger,
    &.FriesOnionRIngs,
    &.RiceMeal{
        .results-loader{background-color: $category-green;}
        .results-item{background: $category-green !important;
            .rank{color: $category-green !important; background: $category-yellow !important;}}
    }
    &.RegularBurger,
    &.Pasta,
    &.ComboMeals{
        .results-loader{background-color: $category-yellow; .lds-loader div{background-color: $theme-green;}}
        .results-item{background: $category-yellow !important; color: $theme-green !important;
            .rank{color: $category-yellow !important; background: $category-redorange !important;}}
        .results-inner-wrapper::-webkit-scrollbar-thumb{background: darken($category-yellow, 10%);}
    }
    &.Pizza,
    &.HealthyOptions,
    &.Coffee{
        .results-loader{background-color: $category-orange;}
        .results-item{background: $category-orange !important;
            .rank{color: $category-orange !important; background: $category-green !important;}}
        .results-inner-wrapper::-webkit-scrollbar-thumb{background: darken($category-orange, 10%);}
    }
    &.MilkTea,
    &.FriedChicken{
        .results-loader{background-color: $category-blue; .lds-loader div{background-color: $regionV-bg;}}
        .results-item{background: $category-blue !important; color: $regionV-bg !important;
            .rank{color: $category-blue !important; background: $regionV-bg !important;}}
        .results-inner-wrapper::-webkit-scrollbar-thumb{background: darken($category-blue, 10%);}
    }
    &.RoastedChicken,
    &.ColdDesserts{
        .results-loader{background-color: $category-purple; .lds-loader div{background-color: $theme-violet;}}
        .results-item{background: $category-purple !important; color: $theme-violet !important;
            .rank{color: $category-purple !important; background: $category-red !important;}}
        .results-inner-wrapper::-webkit-scrollbar-thumb{background: darken($category-purple, 10%);}
    }
    &.BonelessChicken,
    &.Desserts{
        .results-loader{background-color: $category-red;}
        .results-item{background: $category-red !important;
            .rank{color: $category-red !important; background: $category-yellow !important;}}
        .results-inner-wrapper::-webkit-scrollbar-thumb{background: darken($category-red, 10%);}
    }

    &.PremiumBurger,
    &.RegularBurger,
    &.Pizza,
    &.FriedChicken,
    &.RoastedChicken{
        grid-template-areas: 
            "premium regular pizza fried roasted"
            "resultsWrapper resultsWrapper resultsWrapper resultsWrapper resultsWrapper"
            "boneless healthy pasta rice combo"
            "cold dessert coffee milktea fries" 
            !important;
    }
    &.BonelessChicken,
    &.HealthyOptions,
    &.Pasta,
    &.RiceMeal,
    &.ComboMeals{
        grid-template-areas: 
            "premium regular pizza fried roasted"
            "boneless healthy pasta rice combo"
            "resultsWrapper resultsWrapper resultsWrapper resultsWrapper resultsWrapper"
            "cold dessert coffee milktea fries" 
            !important;
    }
    &.ColdDesserts,
    &.Desserts,
    &.Coffee,
    &.MilkTea,
    &.FriesOnionRIngs{
        grid-template-areas: 
            "premium regular pizza fried roasted"
            "boneless healthy pasta rice combo"
            "cold dessert coffee milktea fries" 
            "resultsWrapper resultsWrapper resultsWrapper resultsWrapper resultsWrapper"
            !important;
    }


}
.lb{
    background: $theme-yellowSoft-dark;
    padding: calc($padding-sectionBanner * 2);
    color: white;
    text-align: center;
    .textTitle{
        font-family: "GrabV2";
        font-size: 100px;
        color: $theme-green;
    }
    .imgTitle{
        margin-bottom: 0px !important;
    }
    p{
        margin-bottom: 50px;
    }
    &.regional-tabbed .regions-wrapper{
        width: clamp(70%, 950px, 100%);
        margin: auto;
        display: grid;
        grid-template-areas: "luzon" "results" "visayas" "mindanao";
        .luz{grid-area: luzon;}
        .vis{grid-area: visayas;}
        .min{grid-area: mindanao;}
        .regions-panel{grid-area: results;}
        .regions-tabs{
            width: 100%;
            display: grid;
            // grid-template-columns: repeat(3, 1fr);
            border-radius: 10px;
            margin-top: 10px;
            button{
                border-radius: 10px;
                color: black;
                padding: 5px 10px;
                font-size: 45px;
                font-family: "GrabV2", "Grab", Arial, Helvetica, sans-serif;
                cursor: pointer;
                filter: brightness(120%) saturate(50%) hue-rotate(-30deg) contrast(90%);
                outline: none !important;
                transition: filter 300ms;
                border: 5px solid white;
                border-bottom: none !important;
                &:hover{
                    filter: none;
                }
                &#region{
                    &-Luzon{
                        color: $regionL-txt;
                        background: $regionL-bg;
                        border-color: $regionL-bg;
                    }
                    &-Visayas{
                        color: $regionV-txt;
                        background: $regionV-bg;
                        border-color: $regionV-bg;
                    }
                    &-Mindanao{
                        color: $regionM-txt;
                        background: $regionM-bg;
                        border-color: $regionM-bg;
                    }
                }
            }
        }
        .regions-panel{
            text-align: left;
            background: white;
            color: $theme-green-dark;
            padding: calc($padding-sectionBanner * 1.5);
            padding-top: calc($padding-sectionBanner - 10px);
            border-radius: 0 0 10px 10px;
            border: 2px solid white;
            // transition: border-color 300ms;
            &-inner-wrapper{
                columns: 2;
                column-gap: 20px;
            }
            .regions-item-wrapper{
                display: grid;
                grid-template-columns: 50px 1fr;
                margin-bottom: 1rem;
                font-size: .9rem;
                text-align: center;
                text-transform: uppercase;
                color: $theme-green-dark-mid;
                .rank{
                    font-size: 1.5rem;
                    line-height: 1;
                }
            }
            .results-item-regional{
                // font-size: 20px !important;
                text-transform: uppercase;
                display: block;
                border-radius: 5px;
                padding: 10px;
            }
        }
        .results-loader-wrapper{
            text-align: center;
            .lds-loader div{
                background: $theme-violet;
            }
        }
        &.Luzon{
            grid-template-areas: "luzon" "results" "visayas" "mindanao";
            .regions-tabs button#region-Luzon{filter: none !important; border-radius: 10px 10px 0 0;}
            .regions-panel{border-color: $regionL-bg;}
        }
        &.Visayas{
            grid-template-areas: "luzon" "visayas" "results" "mindanao";
            .regions-tabs button#region-Visayas{filter: none !important; border-radius: 10px 10px 0 0;}
            .regions-panel{border-color: $regionV-bg;}
        }
        &.Mindanao{
            grid-template-areas: "luzon" "visayas" "mindanao" "results";
            .regions-tabs button#region-Mindanao{filter: none !important; border-radius: 10px 10px 0 0;}
            .regions-panel{border-color: $regionM-bg;}
        }
    }
    // &.trending{
    //     background: $theme-salmon;
    //     .textTitle{
    //         color: $category-yellow;
    //     }
    // }
    &.trending{
        .trendings-wrapper{
            background: white;
            color: $theme-green-dark;
            border-radius: 10px;
            padding: calc($padding-sectionBanner * 1.5);
            width: clamp(70%, 950px, 100%);
            margin: auto;
            .items{
                // columns: 2;
                // column-gap: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(5, 85px);
                grid-auto-flow: column;
                &+.trending-page-button-wrapper{
                    button{
                        cursor: pointer;
                        padding: 5px 10px;
                        color: grey;
                        font-size: 20px;
                        margin: 0 auto;
                        background: rgb(209, 209, 209);
                        width: 100px;
                        transition: all 300ms;
                        &:first-child{
                            border-radius: 3px 0 0 3px;
                        }
                        &:last-child{
                            border-radius: 0 3px 3px 0;
                        }
                        &.active{
                            color: white;
                            background: $theme-green-dark-mid;
                        }
                    }
                }
                .results-item-trending{
                    display: grid;
                    grid-template-columns: 50px 1fr;
                    text-align: left;
                    text-transform: uppercase;
                    // margin-bottom: 10px;
                    padding: 10px 10px;
                    border-radius: 5px;
                    &:hover{
                        color: $category-green;
                        background: transparentize($theme-green, .95);
                    }
                    .rank-wrapper{
                        grid-row: 1 / 3;
                        padding: 0;
                    }
                    span{
                        display: inline-block;
                        padding-right: 10px;
                        &.mex{
                            font-size: .75rem;
                            opacity: .5;
                        }
                    }
                }
            }
            ul[role="navigation"]{
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-top: 70px;
                li{
                    a{
                        display: block;
                        cursor: pointer;
                        min-width: 30px;
                        background: $theme-green-light;
                        margin: 0 3px;
                        padding: 5px 20px;
                        border-radius: 2px;
                        opacity: .5;
                        font-size: 16px;
                    }
                    &.selected a{
                        opacity: 1;
                        background: $theme-green;
                        color: white;
                    }
                    &:not(.selected),
                    &:not(.previous),
                    &:not(.next){
                        &:hover a{
                            opacity: .75;
                        }
                    }
                    &.previous,
                    &.next{
                        &.disabled{
                            color: gray;
                        }
                        a{
                            display: block;
                            font-size: 23px;
                            opacity: 1;
                            padding: 0 10px;
                            margin: 0 20px;
                            background: none;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 924px) {
    .categories-wrapper{
        grid-template-areas: 
            "premium regular pizza"
            "resultsWrapper resultsWrapper resultsWrapper"
            "roasted boneless fried"
            "pasta healthy rice"
            "fries cold dessert"
            "coffee milktea combo" !important;
        img{
            max-width: 300px;
        }
        .results-wrapper{
            display: block !important;
        }
        .results-item{
            padding: 10px 10px 10px 20px !important;
        }
        &.PremiumBurger,
        &.RegularBurger,
        &.Pizza{
            grid-template-areas: 
                "premium regular pizza"
                "resultsWrapper resultsWrapper resultsWrapper"
                "roasted boneless fried"
                "pasta healthy rice"
                "fries cold dessert"
                "coffee milktea combo" !important;
        }
        &.RoastedChicken,
        &.BonelessChicken,
        &.FriedChicken{
            grid-template-areas: 
                "premium regular pizza"
                "roasted boneless fried"
                "resultsWrapper resultsWrapper resultsWrapper"
                "pasta healthy rice"
                "fries cold dessert"
                "coffee milktea combo" !important;
        }
        &.Pasta,
        &.HealthyOptions,
        &.RiceMeal{
            grid-template-areas: 
                "premium regular pizza"
                "roasted boneless fried"
                "pasta healthy rice"
                "resultsWrapper resultsWrapper resultsWrapper"
                "fries cold dessert"
                "coffee milktea combo" !important;
        }
        &.FriesOnionRIngs,
        &.ColdDesserts,
        &.Desserts{
            grid-template-areas: 
                "premium regular pizza"
                "roasted boneless fried"
                "pasta healthy rice"
                "fries cold dessert"
                "resultsWrapper resultsWrapper resultsWrapper"
                "coffee milktea combo" !important;
        }
        &.ComboMeals,
        &.Coffee,
        &.MilkTea{
            grid-template-areas: 
                "premium regular pizza"
                "roasted boneless fried"
                "pasta healthy rice"
                "fries cold dessert"
                "coffee milktea combo"
                "resultsWrapper resultsWrapper resultsWrapper" !important;
        }
    }
}


.lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #fff;
        animation: lds-loader 500ms cubic-bezier(0, 0.5, 0.5, 1) infinite;
        &:nth-child(1) {
            left: 8px;
            animation-delay: -0.24s;
        }
        &:nth-child(2) {
            left: 32px;
            animation-delay: -0.12s;
        }
        &:nth-child(3) {
            left: 56px;
            animation-delay: 0;
        }
    }
    @keyframes lds-loader {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }
}

@media screen and (max-width: 800px) {
    .categories-wrapper{
        padding-bottom: 60px !important;
        grid-template-areas: 
            "premium regular pizza"
            "roasted boneless fried"
            "pasta healthy rice"
            "fries cold dessert"
            "coffee milktea combo";
    }
    .lb {
        h1{
            font-size: 60px !important;
        }
        .regions-tabs button{
            font-size: 40px !important;
        }
        .regions-panel{
            // padding: 10px !important;
            .regions-panel-inner-wrapper{
                columns: 1 !important;
            }
        }
        &.trending{
            .trendings-wrapper{
                padding: 2rem;
                .items{
                    grid-template-columns: 1fr;
                    grid-template-rows: unset;
                    grid-auto-flow: unset;
                }
                ul{
                    position: relative;
                    margin-top: 100px;
                    li{display: none;}
                    .previous,
                    .next{
                        display: block;
                        &.disabled a:after{
                            background: rgb(200, 200, 200) !important;
                        }
                        a{
                            font-size: 50px !important;
                            padding: 0 50px !important;
                            position: relative;
                            margin-left: 5px !important;
                            &::after{
                                content: "NEXT";
                                height: 100%;
                                width: 100%;
                                background: $theme-green;
                                color: #fff;
                                border-radius: 5px;
                                position: absolute;
                                left: 0;
                                top: 0;
                                font-size: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                    .previous a::after{
                        content: "PREV";
                    }
                    // li:nth-last-child(2),
                    .selected{
                        display: block;
                        position: absolute;
                        top: -80%;
                        transform: translateX(-50%);
                        a{
                            color: $theme-green-dark !important;
                            background: none !important;
                            font-size: 30px;
                            opacity: 1;
                        }
                        &::after{
                            content: "Page";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            opacity: .5;
                        }
                    }
                    .selected{
                        left: 54%;
                        &::after{
                            left: -40px;
                        }
                    }
                    // li:nth-last-child(2){
                    //     right: 20%;
                    //     &::after{
                    //         content: "of";
                    //         left: -23%;
                    //     }
                    // }
                }
            }
        }
    }
}