$theme-green: #00b14f;
$theme-entryItem: #00b14f;
$theme-green-light: #d9fcde;
$theme-green-dark-mid: #005339;
$theme-green-dark: #003932;
$theme-orange: #ffb800;
$theme-orange-dark: #ff8501;
$theme-salmon: #ff806b;
$theme-yellowGreen: #d3f035;
$theme-cyan: #005673;
// $theme-cyan-dark: #005773;
$theme-violet: #5a3298;
$theme-yellowSoft: #ffe0a1;
$theme-yellowSoft-dark: #ffbc03;

$bootstrap-red: #dc3545;

$category-green: #07b958;
$category-yellow: #d9f23e;
$category-orange: #feba02;
$category-redorange: #ff8403;
$category-blue: #37d2db;
$category-purple: #efc6fc;
$category-red: #ff806b;

$regionL-txt: #ff826c;
$regionL-bg: #ffeaff;
$regionV-txt: #ffb902;
$regionV-bg: #5647a3;
$regionM-txt: #ffe0a1;
$regionM-bg: #352374;
