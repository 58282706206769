@import "colors";
@import "values";

.leaderboard-winners{
    .categories-wrapper {
        max-width: 1012px !important;
        .results-inner-wrapper.winners{
            img{
                height: 50px !important;
                width: auto !important;
            }
            .results-item{
                grid-template-columns: 40px 1fr !important;
                padding-left: 10%;
                grid-gap: 10px;
                a{
                    &:hover{
                        filter: brightness(110%) contrast(110%) saturate(110%);
                    }
                }
                &.platinum{
                    a{
                        display: grid;
                        grid-template-columns:  60% 40%;
                        font-size: 20px;
                        padding-left: 5%;
                    }
                }
                &.gold{
                    align-items: flex-start;
                    padding: 30px !important;
                    padding-left: 10% !important;
                    img{
                        // margin-top: 20px;
                    }
                    .gold-wrapper{
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-column-gap: 10px;
                        grid-row-gap: 30px;
                        a{
                            text-align: center;
                            font-size: 12px;
                            span{
                                display: block;
                                &:first-child{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.active-national{
        background: $theme-yellowSoft;
        color: $theme-green-dark-mid;
    }
    .gffTabs-tab.regional.active,
    &.active-regional{
        background: $theme-orange !important;
        color: $theme-green;
    }
    &.active-trending{
        background: $theme-salmon;
        color: $theme-yellowGreen;
    }
}

.dashboard-winners{
    &.active-national,
    .gffTabs-tab.regional.active,
    &.active-regional,
    &.active-trending{
        background: $theme-green !important;
        color: $theme-green-dark-mid !important;
        .header-title{
            color: $theme-green-dark-mid !important;
        }
    }
    .leaderboard-tabs{
        .gffTabs-tab{
            background: $theme-green !important;
            color: $theme-green-light !important;
            border-color: $theme-green-light !important;
            &.active{
                background: $theme-green-light !important;
                color: $theme-green !important;
            }
        }
        .gffTabs-panels{
            border-color: $theme-green-light !important;
            .gffTabs-panel{
                background: transparentize($color: $theme-green-light, $amount: .75) !important;
            }
        }
    }
}

@media screen and (max-width: 924px) {
    section.categories div.categories-wrapper div.results-inner-wrapper.winners div.results-item{
        &.platinum,
        &.gold{
            padding: 20px !important;
            grid-template-columns: 55px 1fr !important;
        }
        img{
            width: 50px !important;
            height: auto !important;
        }
        &.gold .gold-wrapper{
            grid-template-columns: 1fr !important;
            grid-row-gap: 2px !important;
            a span{
                font-size: 16px !important;
            }
        }
        a{
            display: grid;
            grid-template-columns: 60% 40%;
            text-align: left !important;
            padding: 10px !important;
            padding-left: 5% !important;
            // border: 1px solid rgba(128, 128, 128, 0.145) !important;
            align-items: baseline;
            span{
                display: unset !important;
                padding-right: 30px !important;
            }
        }
    }
    section{
        .textTitle{
            font-size: 30px !important;
        }
        .entries-text{
            margin-top: 20px !important;
        }
    }
    section.categories .awards-wrapper{
        .platinum,
        .gold{
            grid-template-columns: 1fr !important;
            align-items: baseline;    
            grid-template-rows: 170px 1fr;
            p{
                font-size: 16px ;
            }
            div{
                text-align: center !important;
            }
            img{
                margin-bottom: 20px;
            }
        }
    }
}

section.categories .categories-wrapper .results-wrapper .results-inner-wrapper .results-item:hover{
    filter: none !important;
}

.grabv2{
    font-family: "GrabV2";
}