@import "colors";
@import "values";

.category-wrapper.order-campaign{
    &:last-child{
        margin-bottom: 0 !important;
    }
    .category-header{
        color: white;
    }
    .category-body{
        grid-template-columns: repeat(3, 1fr);
    }
    .entry-wrapper{
        padding: 0;
        overflow: hidden;
        border-radius: 5px;
        .entry-photo{
            height: 150px;
            .entry-edit-photo{
                color: white !important;
            }
        }
        .entry-name{
            padding-left: 20px;
            padding-right: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .entry-ranking,
        .entry-votes{
            padding: 10px 20px;
            text-align: center;
            h2, h4, label{
                line-height: 1.25;
            }
            h2{
                font-weight: bold;
                margin: 0;
                font-size: 70px;
            }
            label{
                font-size: 12px;
            }
        }
        .entry-socials{
            text-align: center;
            margin-bottom: 30px;
            a{
                display: inline-block;
                margin: 0 3px;
                font-size: 30px;
                &:hover{
                    filter: brightness(120%);
                }
            }
        }
    }

    
    // categspecific
    &.hot100{
        .category-header{
            padding: 10px 2rem !important;
        }
        .category-header,
        .entry-wrapper{
            color: white;
            background: $category-green;
        }
        .entry-ranking,
        .entry-votes{
            color: $category-yellow;
        }
    }
    &.premiumburger,
    &.ricemeal,
    &.friesonionrings{
        .category-header,
        .entry-wrapper{
            color: white;
            background: $category-green;
        }
        .entry-ranking,
        .entry-votes{
            color: $category-yellow;
        }
    }
    &.regularburger,
    &.pasta,
    &.combomeals{
        .category-header,
        .entry-wrapper{
            color: $category-green;
            background: $category-yellow;
        }
        .entry-ranking,
        .entry-votes{
            color: $theme-orange-dark;
        }
    }
    &.pizza,
    &.coffee,
    &.healthyoptions{
        .category-header,
        .entry-wrapper{
            color: white;
            background: $category-orange;
        }
        .entry-ranking,
        .entry-votes{
            color: $theme-green;
        }
    }
    &.bonelesschicken,
    &.desserts{
        .category-header,
        .entry-wrapper{
            color: $theme-violet;
            background: $category-blue;
        }
        .entry-ranking,
        .entry-votes{
            color: $bootstrap-red;
        }
    }
    &.colddesserts,
    &.roastedchicken{
        .category-header,
        .entry-wrapper{
            color: $theme-violet;
            background: $category-purple;
        }
        .entry-ranking,
        .entry-votes{
            color: $theme-salmon;
        }
    }
    &.friedchicken,
    &.milktea{
        .category-header,
        .entry-wrapper{
            color: white;
            background: $category-red;
        }
        .entry-ranking,
        .entry-votes{
            color: $theme-yellowGreen;
        }
    }
}

@media screen and (max-width: 1150px) {
    section.entries .category-body{
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
@media screen and (max-width: 600px) {
    section.entries .category-body{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}