@font-face {
    font-family: "INTER";
    src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}
@font-face {
    font-family: "QUICKSAND";
    src: url("../fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
}
@font-face {
    font-family: "GrabV2";
    src: url("../fonts/grab/GrabCommunityENv2Inline.ttf") format("truetype"),
        url("../fonts/grab/GrabCommunityENv2_woff.woff") format("woff"),
        url("../fonts/grab/GrabCommunityENv2_woff2.woff2") format("woff2");
}

@font-face {
    font-family: "Grab";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Grab Light";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Light.ttf") format("truetype");
    font-weight: lighter;
    font-style: normal;
}
@font-face {
    font-family: "Grab";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Grab";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Extrabold.ttf") format("truetype");
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: "Grab Medium";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Grab Heavy";
    src: url("../fonts/grab/Grab\ Community\ Solid\ EN-Heavy.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}